import { ChangeEvent, useState, useRef, useContext, KeyboardEvent, useEffect } from 'react';
import { Close as ClearText, DeleteOutline, History, Search as SearchIcon } from '@mui/icons-material';
import { IconButton, InputBase, ListItem, ListItemButton, ListItemIcon, ListItemText, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ctxUser } from '@/contexts/UserContext';
import { driverChatExtraFeature } from '@/tmp/User';
import { ctxPluto } from '@/contexts/TargetContext';
import RefreshIcon from '@mui/icons-material/Refresh';

import './Search.css';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[300],
  '&:hover': {
    backgroundColor: theme.palette.grey[400],
  },
  marginRight: 0,
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100% !important',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  borderBottom: '2px solid transparent',
  '&:before': {
    content: "''",
    width: '100%',
    background: 'var(--mui-palette-secondary-main)',
    height: '2px',
    position: 'absolute',
    left: '0',
    bottom: '0',
    transform: 'scaleX(0)',
    transformOrigin: 'center',
    transition: 'transform 0.3s ease',
  },
  '&:focus-within:before': {
    transform: 'scaleX(1)',
  },
}));

const SearchHistoryContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '100%',
  left: '0',
  width: '100%',
  zIndex: '12',
  backgroundColor: theme.palette.grey[300],
  '& .MuiListItemSecondaryAction-root': {
    right: '0',
  },
  borderEndStartRadius: theme.shape.borderRadius,
  borderEndEndRadius: theme.shape.borderRadius,
}));

export function SearchComponent({
  searchText,
  setSearchText,
  placeholder,
  refreshFunction,
}: {
  searchText: string | undefined;
  setSearchText: (searchText: string) => void;
  placeholder?: string | undefined;
  refreshFunction?: () => void;
}) {
  const [searchTextLocal, setSearchTextLocal] = useState<string | undefined>(searchText);
  const userCtx = useContext(ctxUser);
  const plutoCtx = useContext(ctxPluto);
  const [isFocused, setIsFocused] = useState(false);
  const searchRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!searchText || !plutoCtx || plutoCtx.searchHistory.includes(searchText)) return;
    const newHistory = [searchText, ...plutoCtx.searchHistory];
    if (newHistory.length > 10) newHistory.length = 10;
    plutoCtx.setSearchHistory(newHistory);
  }, [searchText]);

  function setSearchTextWrapper(searchText: string) {
    setSearchTextLocal(searchText);
  }

  function handleClearTextButtonClick() {
    setSearchTextLocal('');
    setSearchText('');
  }

  function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      setSearchText(event.currentTarget.value);
      setIsFocused(false);
      inputRef.current?.blur();
    }
  }

  function getPlaceholder(): string {
    if (placeholder) {
      return placeholder;
    }
    const driverChat = userCtx?.hasExtraFeature(driverChatExtraFeature);
    if (driverChat) {
      return t('filter.driverSearchPlaceholder');
    } else {
      return t('filter.targetSearchPlaceholder');
    }
  }

  function setClickedSearchHistory(text: string): void {
    setSearchText(text);
    setSearchTextWrapper(text);
    setIsFocused(false);
    inputRef.current?.blur();
  }

  function deleteClickedSearchHistory(text: string): void {
    if (!plutoCtx) return;
    const newHistory = plutoCtx.searchHistory.filter((t) => t !== text);
    localStorage.setItem('searchHistory', JSON.stringify(newHistory));
    plutoCtx.setSearchHistory(newHistory);
  }

  function getRefreshButtonClass() {
    return 'search-refresh-button' + (searchTextLocal ? ' search-refresh-button-with-text' : '');
  }

  function getClearButtonClass() {
    return 'search-clear-button' + (searchTextLocal ? ' search-clear-button-with-text' : '');
  }

  if (!plutoCtx) return;
  return (
    <>
      <Search
        ref={searchRef}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={(e: React.FocusEvent<HTMLDivElement>) => {
          if (!searchRef.current?.contains(e.relatedTarget as Node)) {
            setIsFocused(false);
          } else {
            if (!inputRef.current) return;
            inputRef.current?.focus();
          }
        }}
        style={isFocused ? { borderEndStartRadius: 0, borderEndEndRadius: 0 } : {}}
      >
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          inputRef={inputRef}
          value={searchTextLocal || ''}
          onChange={(evt: ChangeEvent<HTMLInputElement>) => setSearchTextWrapper(evt.target.value)}
          placeholder={getPlaceholder()}
          onKeyDown={handleKeyDown}
        />
        {refreshFunction && <RefreshIcon className={getRefreshButtonClass()} onClick={() => refreshFunction()} />}
        <ClearText onClick={() => handleClearTextButtonClick()} className={getClearButtonClass()} />
        {isFocused && (
          <SearchHistoryContainer className="search-history-container">
            {plutoCtx.searchHistory
              .filter((text) => text?.includes(searchTextLocal ? searchTextLocal : ''))
              .map((text) => (
                <ListItem
                  key={text}
                  sx={{ padding: 0 }}
                  secondaryAction={
                    <IconButton sx={{ right: '4px', padding: 0, ':hover': { backgroundColor: 'transparent' } }}>
                      <DeleteOutline onClick={() => deleteClickedSearchHistory(text)} />
                    </IconButton>
                  }
                >
                  <ListItemButton onClick={() => setClickedSearchHistory(text)}>
                    <ListItemIcon sx={{ minWidth: '32px' }}>
                      <History />
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ))}
          </SearchHistoryContainer>
        )}
      </Search>
    </>
  );
}
