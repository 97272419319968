import { User, UserAndMessage } from './User';
import { abortTracking } from '@/tmp';
import { getRequestConfigurationWithDeviceInfos } from '@/utilities/deviceInfoUtils';
import axios from 'axios';

// ha nincs bejelentkezve, akkor null-t adunk vissza
// undefined nem jo, mert az azt jelenti, hogy nem tudjuk, van-e session
// null-nal tudjuk, hogy nincs
export async function getUser() {
  const config = await getRequestConfigurationWithDeviceInfos();
  const url = '/api/v1.1/users/current';
  // does not throw an error if there is no session
  return axios.get<UserAndMessage>(url, Object.assign({}, config, {
    validateStatus: (status: number) => status === 200 || status === 401,
  }));
}
  
export function logoutUser(): void {
  abortTracking();
  const url = '/api/v1.1/logout';
  // FIXME nem kellene ennek post-nak lennie ???
  axios.get<User>(url);
}

export async function loginUser({username, password, rememberMe}: {username: string, password: string, rememberMe?: string}): Promise<User> {
  if (username === undefined || password === undefined) {
    throw new Error('Username and password must be provided');
  }

  const params = new URLSearchParams();
  params.append('username', username);
  params.append('password', password);
  if (rememberMe === "on") {
    params.append("remember-me", "true");
  }

  // throws an error if invalid
  await axios.post('/api/v1.1/login', params, {
    validateStatus: (status: number) => status === 204,
  });

  // betoltjuk az user-t kulon :(
  const user = await getUser();
  return user.data;
}
