import { Dispatch, ReactElement, SetStateAction, createContext, useState } from 'react';
import { TargetDescription } from '@/tmp';
import { PlutoPerson } from '@/generated/model';

export type ReportContextType = {
  selectedTargets: TargetDescription[];
  setSelectedTargets: Dispatch<SetStateAction<TargetDescription[]>>;
  selectedDates: { from: Date; to: Date } | undefined;
  setSelectedDates: Dispatch<SetStateAction<{ from: Date; to: Date } | undefined>>;
  searchText: string | undefined;
  setSearchText: Dispatch<SetStateAction<string | undefined>>;
  selectedTemplateIds: number[];
  setSelectedTemplateIds: Dispatch<SetStateAction<number[]>>;
  addressee: string | undefined;
  setAddressee: Dispatch<SetStateAction<string | undefined>>;
  selectedDrivers: PlutoPerson[];
  setSelectedDrivers: Dispatch<SetStateAction<PlutoPerson[]>>;
  maxTargetNumber: number;
  setMaxTargetNumber: Dispatch<SetStateAction<number>>;
};

export const ctxReport = createContext<ReportContextType | undefined>(undefined);

export function ReportContext({ children }: { children: ReactElement }) {
  const [selectedTargets, setSelectedTargets] = useState<TargetDescription[]>([]);
  const [selectedDates, setSelectedDates] = useState<{ from: Date; to: Date } | undefined>(undefined);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [selectedTemplateIds, setSelectedTemplateIds] = useState<number[]>([]);
  const [addressee, setAddressee] = useState<string | undefined>(undefined);
  const [selectedDrivers, setSelectedDrivers] = useState<PlutoPerson[]>([]);
  const [maxTargetNumber, setMaxTargetNumber] = useState<number>(0);

  return (
    <ctxReport.Provider
      value={{
        selectedTargets,
        setSelectedTargets,
        selectedDates,
        setSelectedDates,
        searchText,
        setSearchText,
        selectedTemplateIds,
        setSelectedTemplateIds,
        addressee,
        setAddressee,
        selectedDrivers,
        setSelectedDrivers,
        maxTargetNumber,
        setMaxTargetNumber,
      }}
    >
      {children}
    </ctxReport.Provider>
  );
}
