import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { UserState } from '@/types';
import { getUser, loginUser, logoutUser, User } from '@/tmp';
import { rethrowIfNotClientException } from '@/utilities/throwableUtils';

export function useUserState(initialState: UserState): [UserState, Dispatch<SetStateAction<UserState>>] {
  const [value, setValue] = useState<UserState>(initialState);

  useEffect(() => {
    function logoutAtExpiryDate(when: number) {
      setTimeout(
        () => {
          setValue('token_failure');
        },
        when / 1000 - Date.now()
      );
    }

    async function init() {
      const params = new URLSearchParams(document.location.search);
      const token = params.get('t');
      if (token) {
        try {
          const user = await loginUser({
            username: '_token_',
            password: token,
          });
          setValue(user);
          if (user.isTokenUser) {
            logoutAtExpiryDate(user.expiry);
          }
          return;
        } catch (error) {
          setValue('token_failure');
          rethrowIfNotClientException(error);
          return;
        }
      }

      try {
        const response = await getUser();
        if (response.status === 200) {
          const userAndMessage = response.data;

          const user = {
            id: userAndMessage.id,
            name: userAndMessage.name,
            isAdmin: userAndMessage.isAdmin,
            isTokenUser: userAndMessage.isTokenUser,
            expiry: userAndMessage.expiry,
            extraFeatures: userAndMessage.extraFeatures ?? [],
            email: userAndMessage.email,
          } satisfies User;
          setValue(user);
          if (user.isTokenUser) {
            logoutAtExpiryDate(user.expiry);
          }
        } else {
          setValue(null);
        }
      } catch (error) {
        setValue(null);
        rethrowIfNotClientException(error);
      }
    }
    init();
  }, []);

  async function onChange(newState: UserState | ((newState: UserState) => UserState)) {
    if (newState instanceof Function) {
      return; // FIXME
    }
    if (newState === undefined) {
      console.log('not permitted');
      return;
    }
    setValue(newState);
    if (newState === null) {
      await logoutUser();
    }
  }

  return [value, onChange];
}
