import { useContext, useState, useRef, useMemo, SyntheticEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { BottomNavigation, BottomNavigationAction, Box, Container, useMediaQuery } from '@mui/material';

import { PopupType } from '@/types';
import { popupDefs } from '@/popups';
import { ctxPopup } from '@/contexts/PopupContext';
import { ctxUser } from '@/contexts/UserContext';

import './Home.css';

export function HomeBottomNav({ logout }: { logout: () => void }) {
  const [value, setValue] = useState(0);
  const popupCtx = useContext(ctxPopup);
  const userCtx = useContext(ctxUser);
  const ref = useRef<HTMLAnchorElement | null>(null);
  const { t } = useTranslation();
  const client = useQueryClient();
  const idiotaRef = useRef<string>('target');

  const popups: PopupType[] = useMemo(() => {
    let popups = popupDefs({
      close: () => {
        // setValue(-1);
        // popupCtx?.setPopupMain(undefined);
        // TODO remove this
        if (idiotaRef.current === 'target') {
          setValue(0);
          popupCtx?.setPopupMain(popups[0].element);
        } else {
          setValue(1);
          popupCtx?.setPopupMain(undefined);
        }
      },
      logout,
      ref,
      client,
      isBottom: true,
    });
    if (userCtx?.isToken()) {
      popups = popups.filter((p) => p.showToken);
    }
    popups = popups
      .filter((p) => {
        const requiredExtraFeature = p.requiredExtraFeature;
        if (!requiredExtraFeature) {
          return true;
        }
        return userCtx?.hasExtraFeature(requiredExtraFeature);
      })
      .filter((p) => p.showMobile);
    return popups;
  }, [logout, popupCtx, userCtx, client]);

  const narrowScreenSize = useMediaQuery('(max-width: 480px)');

  useEffect(() => {
    popupCtx?.setPopupMain(popups[0].element);
    return () => popupCtx?.setPopupMain(undefined);
  }, [popupCtx, popups]);

  if (!popupCtx) return;

  return (
    <Box className="home-nav">
      <Container maxWidth={false} className="home-bottom-nav-container">
        <BottomNavigation
          showLabels
          value={value}
          className="home-bottom-nav-navigator"
          onChange={(_event: SyntheticEvent, newValue: number) => {
            popupCtx.setPopupMain(popups[newValue].element);
            setValue(newValue);
            // FIXME remove this ?
            if (newValue === 0) {
              // ctx?.setMode('target');
              idiotaRef.current = 'target';
            }
            if (newValue === 1) {
              // ctx?.setMode('route');
              idiotaRef.current = 'route';
            }
          }}
        >
          {popups.map((p) =>
            p.ref ? (
              <BottomNavigationAction
                href="#"
                ref={p.ref}
                key={p.title}
                label={narrowScreenSize ? '' : t(p.title)}
                icon={p.icon}
                data-qa-element-id={`bottom-navigation-action-${p.title}`}
                className="home-bottom-nav-action"
              />
            ) : (
              <BottomNavigationAction
                key={p.title}
                label={narrowScreenSize ? '' : t(p.title)}
                icon={p.icon}
                data-qa-element-id={`bottom-navigation-action-${p.title}`}
                className="home-bottom-nav-action"
              />
            )
          )}
        </BottomNavigation>
      </Container>
    </Box>
  );
}
