// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isValidLatLong(v?: any) {
  if (!v) return false;
  if (!v.latitude || !v.longitude) return false;
  if (isNaN(v.latitude) || isNaN(v.longitude)) {
    console.error('isNaN position found!');
    console.trace();
    return false;
  }
  return true;
}
