import { DetailedPosition, Target } from '@/tmp/Target';
import { Close, GpsFixed, LocationOn, Refresh, Schedule, Speed } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import CopyToClipboardButton from './CopyToClipboardButton';
import { fulldate } from '@/utilities/dateUtils';
import { isValidLatLong } from '@/utilities/latlong';

import './TargetTrackingDetails.css';

export default function TargetTrackingDetails({
  target,
  onTargetReload,
  onTargetClose,
}: {
  target: Target;
  onTargetReload: () => void;
  onTargetClose: () => void;
}) {
  const targetName = target.target.name;
  const targetDescription = target.target.description;
  const currentPosition = target.currentPosition;

  function getPositionAsCoordinates(currentPosition: DetailedPosition) {
    if (!isValidLatLong(currentPosition)) {
      return '';
    }
    return `${currentPosition.latitude.toFixed(4)}, ${currentPosition.longitude.toFixed(4)}`;
  }

  return (
    <div className="target-tracking-details">
      <div className="details-header">
        <div className="tracking-target-name">
          {targetName}
          <span>{targetDescription}</span>
        </div>
        <IconButton color="primary" onClick={onTargetReload}>
          <Refresh />
        </IconButton>
        <IconButton color="primary" onClick={onTargetClose}>
          <Close />
        </IconButton>
      </div>
      <div className="tracking-attribute important-attribute">
        <LocationOn className="attribute-icon" color="primary" />
        <span>{currentPosition.geocodedAddress}</span>
      </div>
      <div className="tracking-attribute">
        <GpsFixed className="attribute-icon" color="primary" />
        <span>{getPositionAsCoordinates(currentPosition)}</span>
        <div className="attribute-copy-button">
          <CopyToClipboardButton contentText={getPositionAsCoordinates(currentPosition)} />
        </div>
      </div>
      <div className="tracking-attribute important-attribute">
        <Speed className="attribute-icon" color="primary" />
        <span>{currentPosition.speed} km/h</span>
      </div>
      <div className="tracking-attribute">
        <Schedule className="attribute-icon" color="primary" />
        <span>{fulldate(currentPosition.timeMicros)}</span>
      </div>
    </div>
  );
}
